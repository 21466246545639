import CryptoJS from 'crypto-js';

const key = process.env.VUE_APP_AES_KEY ? process.env.VUE_APP_AES_KEY : '4FWaD2CmQmW67u0jMHgisBEyl2m20MKx';
const iv = process.env.VUE_APP_AES_IV ? process.env.VUE_APP_AES_IV : 'Z1OQ8ds7Iq1t7oH5';

export function encrypt(text) {
    const encrypt = text + "-" + new Date().getTime()

    var ciphertext = CryptoJS.AES.encrypt(encrypt, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    }).toString();

    return ciphertext;
}

export function decrypt(text) {
    var ciphertext = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    return ciphertext.toString(CryptoJS.enc.Utf8);
}