import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/inspection`;

export default {
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show",
      },
    });
  },
  update(id, payload) {
    return Client.post(resource, {
      id: id,
      inspectPoints: payload,
    }, {
      headers: {
        action: "store",
      },
    });
  },
  damageImage(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show-damage-image",
      },
    });
  },
  mainImage(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show-main-image",
      },
    });
  },
  updateMainImage(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "store-main-image",
      },
    });
  },
  updateInspectionNote(payload) {
    return Client.post(resource, {
      ...payload,
    }, {
      headers: {
        action: "store-inspection-note",
      },
    });
  },
};
