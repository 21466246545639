import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/payment`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  exportExcel(payload) {
    let params = prepareFilterParams(payload);
    params.fields = payload.fields;
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "export-excel",
      },
      responseType: "blob",
    });
  },
  exportInvoice(id) {
    return Client.post(resource, { id: id }, {
      headers: {
        action: "export-invoice",
      },
    });
  },
};
