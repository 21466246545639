<template>
  <div>
    <b-overlay
      :show="loading"
      :style="{
        width: fullWidth ? '' : `${pw}px`,
        height: fullWidth ? '' : `${ph + 2}px`,
      }"
    >
      <template #overlay>
        <div class="text-center">
          <b-spinner />
          <p class="mt-2">{{ $t("general.uploading") }}</p>
          <p>{{ uploadPercentage }}%</p>
        </div>
      </template>
      <div
        class="image-uploader flex-center-vertically text-center"
        style="min-height: 150px; height: auto; border: 1px dashed"
        :style="{
          borderColor: activeColor,
          cursor: readonly ? '' : 'pointer',
          backgroundColor: readonly ? secondary : '',
          width: fullWidth ? '' : `${pw}px`,
          height: fullWidth ? '' : `${ph + 2}px`,
        }"
        ref="dragDropBox"
        @click="chooseImage"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div v-if="!uploadedFile && !image">
          <feather-icon
            icon="ImageIcon"
            size="30"
            :style="{
              color: readonly ? '#ffffff' : activeColor,
            }"
          />
          <div
            class="mt-2"
            :style="{
              color: readonly ? '#ffffff' : activeColor,
            }"
          >
            {{ $t("button.clickOrDragDropToUpload") }}
          </div>
        </div>
        <div v-if="uploadedFile || image">
          <img
            class="img-fluid"
            :src="uploadedFile ? uploadedFile : image"
            alt=""
            :style="{
              height: fullWidth ? '' : `${ph}px`,
            }"
          />
        </div>
      </div>
      <input
        type="file"
        style="display: none"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="fileInput"
        accept=".pdf,.jpg,.jpeg,.png"
      />
    </b-overlay>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { BOverlay, BSpinner } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const FileRepository = Repository.get("file");

export default {
  delimiters: ["${", "}"],

  components: {
    BOverlay,
    BSpinner,
  },

  props: {
    image: {},
    value: {},
    size: {},
    pw: {},
    ph: {},
    path: {
      type: String,
      default: "image",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Image",
    },
    readonly: {},
  },

  data: function () {
    return {
      loading: false,
      uploadedFile: "",
      filelist: [],
      uploadPercentage: 0,
    };
  },

  methods: {
    onChange() {
      if (this.readonly) {
        return;
      }

      this.filelist = [...this.$refs.fileInput.files];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedFile = e.target.result;
        const file = {
          path: this.path,
          file: this.filelist[0],
        };
        this.upload(file);
      };
      reader.readAsDataURL(this.filelist[0]);
    },
    async upload(file) {
      this.loading = true;
      this.uploadPercentage = 0;
      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("path", file.path);

      let response = await Client.post(
        `${Config.basePath}/v1/upload`,
        formData,
        {
          headers: {
            action: "upload-file",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        }
      );
      this.loading = false;
      if (response?.data?.code == 200) {
        const data = response?.data?.data;
        this.$emit("input", data.mediaId);
        this.$emit("change", data.mediaId);
      }
    },
    remove() {
      this.filelist.splice(0, 1);
      this.uploadedFile = null;
      this.$emit("input", null);
      this.$emit("change", null);
    },
    dragover(event) {
      if (this.readonly) {
        return;
      }

      event.preventDefault();
      this.activeColor = this.primary;
    },
    dragleave(event) {
      if (this.readonly) {
        return;
      }
      this.activeColor = this.secondary;
    },
    drop(event) {
      if (this.readonly) {
        return;
      }
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    chooseImage() {
      if (this.readonly) {
        return;
      }
      this.$emit("blur", null);
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
  },
  setup() {
    // App Name
    const { primary, secondary } = $themeConfig.color;

    let activeColor = secondary;

    return {
      primary,
      secondary,
      activeColor,
    };
  },
};
</script>

<style scoped>
.flex-center-vertically {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
