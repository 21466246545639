export default [
  {
    path: '/bidding-report',
    name: 'bidding-report',
    component: () => import('@/views/biddingReport/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.biddingReport',
      resource: 'bidding-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.biddingReport',
          active: true,
        },
      ],
    },
  },
]
