<template>
  <b-input-group :prepend="prefix" :append="suffix">
    <the-mask
      v-model="data"
      :mask="['### ### ###', '### ### ####']"
      class="form-control"
      @blur.native="blur"
      :disabled="disabled"
      type="tel"
      :placeholder="placeholder"
    />
  </b-input-group>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { BInputGroup } from "bootstrap-vue";

export default {
  components: {
    TheMask,
    BInputGroup,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {
      default: "",
    },
    suffix: {
      default: "",
    },
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: null,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  watch: {
    data: function (value) {
      this.$emit("input", value);
    },
    value: function (value) {
      if (value) {
        this.data = value;
      }
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
  },
};
</script>

<style scoped>
</style>
