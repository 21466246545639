export default [
  {
    path: '/dealer',
    name: 'list-dealer',
    component: () => import('@/views/dealer/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.dealer',
      resource: 'dealer',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listDealer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dealer/create',
    name: 'create-dealer',
    component: () => import('@/views/dealer/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.dealer',
      navActiveLink: 'list-dealer',
      resource: 'dealer',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listDealer',
          to: { name: 'list-dealer' }
        },
        {
          text: 'breadcrumb.createDealer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dealer/:id/update',
    name: 'update-dealer',
    component: () => import('@/views/dealer/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.dealer',
      navActiveLink: 'list-dealer',
      resource: 'dealer',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listDealer',
          to: { name: 'list-dealer' }
        },
        {
          text: 'breadcrumb.updateDealer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dealer/:id',
    name: 'view-dealer',
    component: () => import('@/views/dealer/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.dealer',
      navActiveLink: 'list-dealer',
      resource: 'dealer',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listDealer',
          to: { name: 'list-dealer' }
        },
        {
          text: 'breadcrumb.viewDealer',
          active: true,
        },
      ],
    },
  },
]
