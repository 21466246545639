export default [
  {
    path: '/setting',
    name: 'list-setting',
    component: () => import('@/views/setting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.setting',
      resource: 'setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/setting/:id/update',
    name: 'update-setting',
    component: () => import('@/views/setting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.setting',
      navActiveLink: 'list-setting',
      resource: 'setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSetting',
          to: { name: 'list-setting' }
        },
        {
          text: 'breadcrumb.updateSetting',
          active: true,
        },
      ],
    },
  },
]
