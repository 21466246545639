<template>
  <b-form @submit.prevent="submit" v-shortkey="['enter']" @shortkey="submit()">
    <slot></slot>
  </b-form>
</template>

<script>
  import { BForm } from "bootstrap-vue";

  export default {
    components: {
      BForm,
    },
    props: {
      readonly: {},
      form: {},
      disabled: {},
      comfirmText: {
        default: "alert.unableToRevertAfterSave",
      },
      okVariant: {
        default: "primary",
      },
      okTitle: {
        default: "button.save",
      },
    },
    methods: {
      submit() {
        this.$emit("submit");
      },

      confirm() {
        this.$bvModal
          .msgBoxConfirm(this.$t(this.comfirmText), {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: this.okVariant,
            okTitle: this.$t(this.okTitle),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$emit("submit");
            }
          });
      },
    },
  };
</script>

<style scoped></style>
