import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/vehicle/status`;

export default {
  checkin(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "checkin",
      },
    });
  },
  completeInspection(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "complete-inspection",
      },
    });
  },
  updateToBid(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "bidded",
      },
    });
  },
  rejectBid(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "reject-bid",
      },
    });
  },
};
