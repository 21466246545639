import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole, path = null, permissions = []) => {
  console.log(userRole)

  if (userRole === 'admin') {
    let route = null
    for (const permission of permissions) {
      if (route) {
        break
      }

      if (permission.route) {
        route = permission.route
      }
    }

    return path || (route || '/')
  }
  return { name: 'login' }
}

export const logout = () => {
  // Remove token from localStorage
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')
}

export const login = data => {
  useJwt.setToken(data.accessToken)
  useJwt.setRefreshToken(data.refreshToken)
  const { user } = data
  localStorage.setItem('userData', JSON.stringify(user))
}


export function isObjectNotEmpty(obj) {
  // the properties of the object
  let isContainTrue = [];
  for (let key in obj) {
    const value = obj[key];

    // If the property is an array, check if it's not empty
    if (value) {
      if (Array.isArray(value) && value) {
        if (value.length) {
          isContainTrue.push(true);
        }
      } else if (typeof value == "object" && value) {
        if (Object.keys(value)?.length) isContainTrue.push(true);
      } else {
        isContainTrue.push(true);
      }
    }
  }

  const isTrue = isContainTrue?.find((v) => v);
  return isTrue;
}
