import { encrypt } from '@/libs/crypto-js';
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';
import Swal from 'sweetalert2';
import jwtDefaultConfig from './jwtDefaultConfig';
// import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        // If token is present add it to request's Authorization Header
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = encrypt(this.jwtConfig.authorizationKey)
        // If token is present add it to request's Authorization Header
        if (config.params) {
          config.params = decamelizeKeys(config.params)
          if (config.params.order) {
            config.params.order = decamelize(config.params.order)
          }
        }
        if (config.data) {
          config.data = decamelizeKeys(config.data)
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      // response => response,
      response => {
        if (response.data && (response.headers['content-type'].startsWith('application/json') || response.headers['content-type'] === 'application/json;charset=UTF-8, application/json' || response.headers['content-type'] === 'application/json')) {
          response.data = camelizeKeys(response.data)
        }
        return Promise.resolve(response)
      },
      error => {
        const title = error.response?.data?.message
        const message = error.response?.data?.errormesasge
        Swal.fire({
          title,
          icon: 'info',
          text: message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.baseUrl + this.jwtConfig.authEndpoint, ...args, {
      headers: {
        action: this.jwtConfig.loginAction,
      },
    })
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.baseUrl + this.jwtConfig.authEndpoint, {}, {
      headers: {
        action: this.jwtConfig.refreshAction,
        'Re-Authentication': encrypt(this.getRefreshToken()),
      },
    })
  }
}
