<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    v-model="isShow"
    @hide="checkIsCanClose"
  >
    <h4 class="mb-2">
      {{ $t("general.pleaseResetPassword") }}
    </h4>
    <validation-observer ref="updatePasswordForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="updatePasswordForm"
        :form="$refs.updatePasswordForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data"> </n-input>
        <b-row>
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
            >
              {{ $t("button.submit") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import { initialAbility } from "@/libs/acl/config";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";

const ProfileRepository = Repository.get("profile");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    NFormConfirmation,
    NInput,
    NButtonLoading,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      formInput: [
        {
          key: "newPassword",
          placeholder: "placeholder.password",
          label: "field.newPassword",
          rules: "required|password|min:8",
          type: "password",
          cols: 6,
          // description: "general.passwordDescription",
          // hideDescription: true,
        },
        {
          key: "retypeNewPassword",
          label: "field.retypeNewPassword",
          placeholder: "placeholder.password",
          rules: "required|confirmed:newPassword",
          type: "password",
          cols: 6,
        },
      ],
      data: {
        newPassword: "",
        retypeNewPassword: "",
      },
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    checkIsCanClose(bvModalEvent) {
      if (this.userData.isForceResetPassword) {
        bvModalEvent.preventDefault();
        this.isShow = true;
      }
    },
    submit() {
      this.$refs.updatePasswordForm.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    save() {
      this.loading = true;
      ProfileRepository.resetPassword(this.data)
        .then((response) => {
          this.getProfile();
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$refs.updatePasswordForm.setErrors(
              error.response?.data?.message
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getProfile() {
      ProfileRepository.getProfile().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.$store.commit("profile/UPDATE_PROFILE", data);
          localStorage.setItem("userData", JSON.stringify(data));
          let newAbility = [...initialAbility];
          if (data.ability) {
            if (data.isReadonly) {
              data.ability.forEach((x) => {
                x.action = "read";
                return x;
              });
            }
            newAbility = [...data.ability, ...initialAbility];
          }
          // Updating user ability in CASL plugin instance
          this.$ability.update([]);
          this.$ability.update(newAbility);
          this.isShow = data.isForceResetPassword;
        }
      });
    },
  },
};
</script>
