export default {
  namespaced: true,
  state: {
    vehicles: ['increasement', 'vehicleId', 'vinNumber', 'year', 'brandName', 'model', 'sellTarget', 'status', 'actions'],
    // vehicles: ['increasement', 'checkinDate', 'updatedBy', 'vinNumber', 'year', 'brandName', 'model', 'engineCapacity', 'transmission', 'sellTarget', 'status', 'actions'],
    scheduleVehicles: ['increasement', 'vinNumber', 'year', 'brandName', 'model', 'engineCapacity', 'transmission', 'price', 'status', 'actions'],
    scheduleDetailVehicles: ['increasement', 'vinNumber', 'year', 'brandName', 'model', 'engineCapacity', 'transmission', 'reservePrice', 'finalPrice', 'dealer', 'status', 'actions'],
    biddingReport: ['increasement', 'auctionDate', 'vinNumber', 'year', 'brandName', 'model', 'engineCapacity', 'transmission', 'reservePrice', 'finalPrice', 'dealer', 'status', 'actions'],
    paymentHistory: ['increasement', 'dealer', 'vinNumber', 'year', 'brandName', 'model', 'engineCapacity', 'transmission', 'finalPrice', 'paymentMethod', 'payAmount', 'remainAmount', 'payDate', 'status', 'actions'],
  },
  getters: {},
  mutations: {
    SET_VEHICLE_COLUMN(state, val) {
      state.vehicles = val
    },
    SET_SCHEDULE_VEHICLE_COLUMN(state, val) {
      state.scheduleVehicles = val
    },
    SET_SCHEDULE_DEATAIL_VEHICLE_COLUMN(state, val) {
      state.scheduleDetailVehicles = val
    },
    SET_BIDDING_REPORT_COLUMN(state, val) {
      state.biddingReport = val
    },
    SET_PAYMENT_HISTORY_COLUMN(state, val) {
      state.paymentHistory = val
    },
  },
  actions: {},
}
