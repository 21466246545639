<template>
  <b-row>
    <b-col :sm="field.type === 'divider' ? 12 : field.cols ? field.cols : 6" v-for="field in fields" :key="field.key"
      :class="`${field.class}`">
      <div v-if="!field.hide">
        <slot :name="field.key" :field="field">
          <div class="d-flex mt-2" v-if="field.type === 'divider'">
            <feather-icon size="19" :icon="field.icon" v-if="field.icon" />
            <h4 class="ml-50">{{ $t(field.label) }}</h4>
          </div>
          <b-form-group :label-for="field.key" :description="field.hideDescription
              ? field.description
                ? $t(field.description)
                : ''
              : getDesciption(field)
            " v-if="field.type !== 'divider'" :class="field.class">
            <validation-provider #default="{ errors }" :vid="field.key" :name="field.label" :rules="field.rules">
              <label v-if="field.type !== 'checkbox' && !field.hideLabel">{{ $t(field.label) }}
                <span class="text-muted" v-if="isOptional(field.rules) && !field.hideOptional">
                  - {{ $t("field.optional") }}
                </span>
              </label>
              <b-form-input v-if="field.type === 'text'" v-model="data[field.key]" :ref="field.key"
                :placeholder="$t(field.label)" :name="field.key" :disabled="disabled ? disabled : field.disabled"
                @input="onChange" :class="errors.length > 0 ? 'is-invalid' : null" autocomplete="off" />
              <b-form-datepicker v-if="field.type === 'date'" v-model="data[field.key]" :ref="field.key"
                :name="field.key" :placeholder="$t(field.label)" :disabled="disabled ? disabled : field.disabled"
                @input="onChange" :class="errors.length > 0 ? 'is-invalid' : null" :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }" />
              <n-date-picker v-if="field.type === 'date-picker'" :ref="field.key" :name="field.key"
                :placeholder="$t(field.label)" :disabled="disabled ? disabled : field.disabled"
                :disable-type="field.disableType" v-model="data[field.key]"
                :class="errors.length > 0 ? 'is-invalid' : null" />
              <n-date-time-picker v-if="field.type === 'date-time-picker'" :ref="field.key" :name="field.key"
                :placeholder="$t(field.label)" :disabled="disabled ? disabled : field.disabled"
                :disable-type="field.disableType" v-model="data[field.key]"
                :class="errors.length > 0 ? 'is-invalid' : null" />
              <n-year-picker v-if="field.type === 'year-picker'" :ref="field.key" :name="field.key"
                :placeholder="$t(field.label)" :disabled="disabled ? disabled : field.disabled"
                :disable-type="field.disableType" v-model="data[field.key]"
                :class="errors.length > 0 ? 'is-invalid' : null" />
              <n-password-input v-if="field.type === 'password'" v-model="data[field.key]" :ref="field.key"
                :id="field.key" :name="field.key" :placeholder="$t(field.label)" :errors="errors"
                :disabled="disabled ? disabled : field.disabled" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-password-input>
              <n-currency-input v-if="field.type === 'currency'" v-model="data[field.key]" :ref="field.key"
                :id="field.key" :name="field.key" :placeholder="$t(field.label)" :errors="errors"
                :disabled="disabled ? disabled : field.disabled" :prefix="field.prefix" :suffix="field.suffix"
                :suffix-localize="field.suffixLocalize" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-currency-input>
              <n-tel-input v-if="field.type === 'tel'" v-model="data[field.key]" :ref="field.key" :id="field.key"
                :name="field.key" :prefix="field.prefix" :suffix="field.suffix" :placeholder="$t(field.label)"
                :errors="errors" :disabled="disabled ? disabled : field.disabled" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-tel-input>
              <n-international-tel-input v-if="field.type === 'international-tel'" v-model="data[field.key]"
                :ref="field.key" :id="field.key" :name="field.key" :prefix="field.prefix" :suffix="field.suffix"
                :placeholder="$t(field.label)" :errors="errors" :disabled="disabled ? disabled : field.disabled"
                @input="onChange" :class="errors.length > 0 ? 'is-invalid' : null"></n-international-tel-input>
              <b-form-radio-group v-if="field.type === 'radio'" :ref="field.key" :id="field.key"
                v-model="data[field.key]" :disabled="field.disabled" @change="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null">
                <b-form-radio :value="option.value" v-for="option in field.options" :key="option.value">{{
                  $t(option.text) }}</b-form-radio>
              </b-form-radio-group>
              <b-form-checkbox v-if="field.type === 'checkbox'" :ref="field.key" :id="field.key"
                v-model="data[field.key]" :name="field.key" :value="true" :unchecked-value="false"
                :disabled="disabled ? disabled : field.disabled" :class="errors.length > 0 ? 'is-invalid' : null">
                {{ $t(field.label) }}
              </b-form-checkbox>
              <n-single-image-uploader v-if="field.type === 'single-image'" :ref="field.key" :pw="field.pw"
                :ph="field.ph" :fullWidth="field.fullWidth" :image="initValue[field.initKey]" v-model="data[field.key]"
                :readonly="disable ? disabled : field.disabled" @change="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-single-image-uploader>
              <n-async-single-image-uploader v-if="field.type === 'async-single-image'" :ref="field.key"
                :path="field.path" :pw="field.pw" :ph="field.ph" :fullWidth="field.fullWidth"
                :image="initValue[field.initKey]" v-model="data[field.key]"
                :readonly="disabled ? disabled : field.disabled" @change="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-async-single-image-uploader>
              <b-form-textarea v-if="field.type === 'textarea'" v-model="data[field.key]" :ref="field.key"
                :id="field.key" :placeholder="$t(field.label)" :rows="field.rows ? field.rows : 2"
                :max-rows="field.maxRows ? field.maxRows : 6" :readonly="disabled ? disabled : field.disabled"
                :disabled="disabled ? disabled : field.disabled" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></b-form-textarea>
              <n-color-picker v-if="field.type === 'color-picker'" v-model="data[field.key]" :ref="field.key"
                :id="field.key" :name="field.key" :placeholder="$t(field.label)" :errors="errors"
                :disabled="disabled ? disabled : field.disabled" :prefix="field.prefix" :suffix="field.suffix"
                @input="onChange" :class="errors.length > 0 ? 'is-invalid' : null"></n-color-picker>
              <n-single-select v-if="field.type === 'single-selection'" v-model="data[field.key]" :ref="field.key"
                :name="field.key" :init-options="data[field.key]" :options="field.options"
                :selection-key="field.selectionKey" :selection-label="field.selectionLabel"
                :translatable="field.translatable" :readonly="disabled ? disabled : field.disabled" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-single-select>
              <n-multi-select v-if="field.type === 'multi-selection'" v-model="data[field.key]" :ref="field.key"
                :name="field.key" :init-options="data[field.key]" :options="field.options"
                :selection-key="field.selectionKey" :selection-label="field.selectionLabel"
                :readonly="disabled ? disabled : field.disabled" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-multi-select>
              <n-async-multi-select v-if="field.type === 'asyn-multi-selection'" v-model="data[field.key]"
                :ref="field.key" :name="field.key" :init-options="data[field.key]" :repository="field.repository"
                :selection-key="field.selectionKey" :selection-label="field.selectionLabel"
                :readonly="disabled ? disabled : field.disabled" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-async-multi-select>
              <n-async-single-select v-if="field.type === 'asyn-single-selection'" v-model="data[field.key]"
                :ref="field.key" :name="field.key" :init-options="data[field.key]" :repository="field.repository"
                :selection-key="field.selectionKey" :selection-label="field.selectionLabel"
                :readonly="disabled ? disabled : field.disabled" :clearable="field.clearable" :reduce="field.reduce"
                :localization="field.localization" :searchFields="field.searchFields" @input="onChange"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-async-single-select>
              <n-lat-lng-picker v-if="field.type === 'latlng-picker'" v-model="data[field.key]"
                :latitude="Number(data[field.latitude])" :longitude="Number(data[field.longitude])" :ref="field.key"
                :name="field.key" :init-options="data[field.key]" :repository="field.repository"
                :selection-key="field.selectionKey" :selection-label="field.selectionLabel"
                :readonly="disabled ? disabled : field.disabled" :class="errors.length > 0 ? 'is-invalid' : null"
                @change="changeLatLng($event, field)"></n-lat-lng-picker>
              <n-document-uploader v-if="field.type === 'document-uploader'" v-model="data[field.key]" :ref="field.key"
                :name="field.key" :readonly="disabled ? disabled : field.disabled"
                :class="errors.length > 0 ? 'is-invalid' : null" />
              <n-async-file-uploader v-if="field.type === 'async-file-uploader'" v-model="data[field.key]" type="file"
                :file="field.fileKey ? data[field.fileKey] : {}" :state="errors.length > 0 ? false : null"
                :browse-text="$t('button.browse')" :placeholder="$t('button.chooseFileOrDropHere')"
                :drop-placeholder="$t('button.dropHere')"
                :class="errors.length > 0 ? 'is-invalid' : null"></n-async-file-uploader>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BRow,
    BCol,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
  } from "bootstrap-vue";
  import NPasswordInput from "@/components/NPasswordInput";
  import NAsyncMultiSelect from "@/components/NAsyncMultiSelect";
  import NMultiSelect from "@/components/NMultiSelect";
  import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
  import NSingleImageUploader from "@/components/NSingleImageUploader";
  import NCurrencyInput from "@/components/NCurrencyInput";
  import NTelInput from "@/components/NTelInput";
  import NInternationalTelInput from "@/components/NInternationalTelInput";
  import NSingleSelect from "@/components/NSingleSelect";
  import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
  import NLatLngPicker from "@/components/NLatLngPicker";
  import NColorPicker from "@/components/NColorPicker";
  import NDatePicker from "@/components/NDatePicker";
  import NDateTimePicker from "@/components/NDateTimePicker";
  import NYearPicker from "@/components/NYearPicker";
  import NDocumentUploader from "@/components/NDocumentUploader";
  import NAsyncFileUploader from "./NAsyncFileUploader.vue";

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      NPasswordInput,
      BFormRadioGroup,
      BFormRadio,
      NSingleImageUploader,
      BBadge,
      BRow,
      BCol,
      BFormTextarea,
      NAsyncMultiSelect,
      NAsyncSingleSelect,
      BFormCheckbox,
      NCurrencyInput,
      NTelInput,
      NInternationalTelInput,
      NMultiSelect,
      NSingleSelect,
      BFormDatepicker,
      NAsyncSingleImageUploader,
      NLatLngPicker,
      NColorPicker,
      NDatePicker,
      NDateTimePicker,
      NYearPicker,
      NDocumentUploader,
      NAsyncFileUploader,
    },
    props: {
      value: {},
      initValue: {
        type: Object,
        default: function () {
          return {};
        },
      },
      fields: {
        type: Array,
        default: function () {
          return [];
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        data: {},
      };
    },
    computed: {},
    watch: {
      data: function (newValue, oldValue) {
        this.$emit("input", newValue);
      },
      initValue: function (newValue, oldValue) {
        this.data = {
          ...this.data,
          ...newValue,
        };
      },
    },
    mounted() {
      this.data = {
        ...this.value,
      };
    },
    methods: {
      reset() {
        this.fields.forEach((element) => {
          if (
            this.$refs[element.key] &&
            typeof this.$refs[element.key][0].reset === "function"
          ) {
            this.$refs[element.key][0].reset();
          }
          this.data[element.key] = null;
        });
      },
      setValue(value) {
        this.data = {
          ...value,
        };
      },
      getDesciption(field) {
        let image = ["single-image", "async-single-image"];
        if (image.includes(field.type)) {
          return this.$t("field.recommendDimension", {
            ph: field.ph,
            pw: field.pw,
          });
        }
        return "";
      },
      isOptional(rules) {
        if (!rules) {
          return true;
        }

        return !rules.includes("required");
      },
      onChange() {
        this.$emit("input", this.data);
      },
      changeLatLng(position, field) {
        this.data[field.latitude] = position.lat;
        this.data[field.longitude] = position.lng;
      },
    },
  };
</script>

<style scoped></style>
