import VueCompositionAPI from '@vue/composition-api'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import Vue from 'vue'

import i18n from '@/libs/i18n'
import App from './App.vue'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/google-map'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vee-validate'
import '@/libs/vue-shortkey'
import { VBTooltipPlugin } from 'bootstrap-vue'
import { camelize } from 'humps'

// Filter
import '@/filters/currencyFilter'
import '@/filters/dateFilter'

// Custom
import LoadProfile from '@/components/LoadProfile'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('load-profile', LoadProfile);
Vue.component('qr-code', VueQRCodeComponent);

import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'
Vue.use(imagePreview)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VBTooltipPlugin);

// Composition API
Vue.use(VueCompositionAPI)

import scheduleState from "@/data/scheduleState"
import vehicleState from "@/data/vehicleState"

Vue.mixin({
  data() {
    return {
      vehicleState: vehicleState,
      scheduleState: scheduleState,
    };
  },
  methods: {
    trans(data, label, locale) {
      return data[camelize(`${label}_${locale}`)];
    },
  }
});

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
