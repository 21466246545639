import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/vehicle-import`;

export default {
  import(payload) {
    return Client.post(resource, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        action: "store",
      },
    });
  },
};
