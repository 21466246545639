import AuctionScheduleRemarkRepository from './Api/AuctionScheduleRemarkRepository'
import AuctionScheduleRepository from './Api/AuctionScheduleRepository'
import BidRepository from './Api/BidRepository'
import ConfigurationRepository from './Api/ConfigurationRepository'
import DashboardRepository from './Api/DashboardRepository'
import DealerRemarkRepository from './Api/DealerRemarkRepository'
import DealerRepository from './Api/DealerRepository'
import DepartmentRepository from './Api/DepartmentRepository'
import DistrictRepository from './Api/DistrictRepository'
import FileRepository from './Api/FileRepository'
import ForgotPasswordRepository from './Api/ForgotPasswordRepository'
import GuidelineRepository from './Api/GuidelineRepository'
import ImportVehicleRepository from './Api/ImportVehicleRepository'
import InpsectorRepository from './Api/InpsectorRepository'
import InspectionRepository from './Api/InspectionRepository'
import LocationRepository from './Api/LocationRepository'
import LotRepository from './Api/LotRepository'
import PaymentAdjustmentRepository from './Api/PaymentAdjustmentRepository'
import PaymentDealerRepository from './Api/PaymentDealerRepository'
import PaymentDocumentRepository from './Api/PaymentDocumentRepository'
import PaymentHistoryRepository from './Api/PaymentHistoryRepository'
import PaymentRepository from './Api/PaymentRepository'
import PaymentVehicleRepository from './Api/PaymentVehicleRepository'
import PermissionRepository from './Api/PermissionRepository'
import ProfileRepository from './Api/ProfileRepository'
import ProvinceRepository from './Api/ProvinceRepository'
import RoleRepository from './Api/RoleRepository'
import UserMobileRepository from './Api/UserMobileRepository'
import UserRepository from './Api/UserRepository'
import VehicleBrandRepository from './Api/VehicleBrandRepository'
import VehicleColorRepository from './Api/VehicleColorRepository'
import VehicleCommentRepository from './Api/VehicleCommentRepository'
import VehicleFuelTypeRepository from './Api/VehicleFuelTypeRepository'
import VehicleGatePassRepository from './Api/VehicleGatePassRepository'
import VehicleLogRepository from './Api/VehicleLogRepository'
import VehicleModelRepository from './Api/VehicleModelRepository'
import VehicleRepository from './Api/VehicleRepository'
import VehicleStatusRepository from './Api/VehicleStatusRepository'
import VehicleTransferLogRepository from './Api/VehicleTransferLogRepository'
import VehicleUpdateRequestRepository from './Api/VehicleUpdateRequestRepository'
import ZoneRepository from './Api/ZoneRepository'

const repositories = {
  profile: ProfileRepository,
  dashboard: DashboardRepository,
  user: UserRepository,
  userMobile: UserMobileRepository,
  role: RoleRepository,
  permission: PermissionRepository,
  file: FileRepository,
  department: DepartmentRepository,
  vehicleBrand: VehicleBrandRepository,
  vehicleColor: VehicleColorRepository,
  vehicleFuelType: VehicleFuelTypeRepository,
  vehicle: VehicleRepository,
  importVehicle: ImportVehicleRepository,
  zone: ZoneRepository,
  lot: LotRepository,
  dealer: DealerRepository,
  dealerRemark: DealerRemarkRepository,
  province: ProvinceRepository,
  district: DistrictRepository,
  inspection: InspectionRepository,
  vehicleLog: VehicleLogRepository,
  vehicleUpdateRequest: VehicleUpdateRequestRepository,
  vehicleComment: VehicleCommentRepository,
  auctionSchedule: AuctionScheduleRepository,
  auctionScheduleRemark: AuctionScheduleRemarkRepository,
  bid: BidRepository,
  payment: PaymentRepository,
  paymentDealer: PaymentDealerRepository,
  paymentVehicle: PaymentVehicleRepository,
  configuration: ConfigurationRepository,
  guideline: GuidelineRepository,
  vehicleStatus: VehicleStatusRepository,
  vehicleGatePass: VehicleGatePassRepository,
  vehicleModel: VehicleModelRepository,
  inspector: InpsectorRepository,
  paymentAdjustment: PaymentAdjustmentRepository,
  paymentDocument: PaymentDocumentRepository,
  paymentHistory: PaymentHistoryRepository,
  forgotPassword: ForgotPasswordRepository,
  location: LocationRepository,
  vehicleTransferLog: VehicleTransferLogRepository,
}

export default {
  get: name => repositories[name],
}
