export default {
    PENDING_ARRIVAL: 1,
    READY_TO_INSPECT: 2,
    COMPLETE_INSPECTION: 3,
    AMENDMENT_REQUIRED: 4,
    READY_TO_SCHEDULE: 5,
    SCHEDULED: 6,
    LIVE_BIDDING: 7,
    BIDDED: 8,
    NO_BID: 9,
    REMOVE_FROM_BID: 10,
    ACCEPT_BID: 11,
    REJECT_BID: 12,
    PENDING_PAYMENT: 13,
    PARTIAL_PAID: 14,
    PAID: 15,
    SOLD: 16,
    VERIFIED_PAYMENT: 17,
}