import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/cargetpass`;

export default {
  export(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "export",
      },
      responseType: "blob",
    });
  },
};
