import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/district`;

export default {
  options(payload) {
    const queries = [{
      searchby: "name_en",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name_km",
      searchoperator: "%",
      search: payload.search
    }];
    let params = prepareOptionFilterParams(payload, queries, "OR", "id", "asc", false);
    let filters = {
      filters: [],
      filtertype: "AND",
    };
    payload.query.forEach(element => {
      filters.filters.push(element);
    });
    params.filters.push(filters);
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
