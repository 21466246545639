import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/auction/schedule`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  listBidTransaction(payload) {
    let params = prepareFilterParams(payload);
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "list-bid-transaction",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  cancel(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "cancel",
      },
    });
  },
  listVehicle(payload) {
    let params = prepareFilterParams(payload);
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "list-vehicle",
      },
    });
  },
  exportExcelVehicle(payload) {
    let params = prepareFilterParams(payload);
    params.fields = payload.fields;
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "export-excel-vehicle",
      },
      responseType: "blob",
    });
  },
  addVehicles(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "add-vehicles",
      },
    });
  },
  removeVehicle(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "remove-vehicle",
      },
    });
  },
  listBidder(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "list-bidder",
      },
    });
  },
};
