import currency from 'currency.js';
import Vue from 'vue';

Vue.filter('currency', function (value) {
  value??=0;
  if (typeof value === 'undefined' || value == null) {
    return '';
  }

  let precision = 0;
  let money = value.toString().split(".");
  if (money.length > 1) {
    precision = money[1].length;
  }

  const USD = value => currency(value, {
    precision: precision
  });
  return USD(value).format();
});
