<template>
  <b-button
    :variant="variant"
    :disabled="loading || disabled"
    :type="type"
    @click="submit"
    v-bind="$attrs"
  >
    <b-spinner small label="Spinning" v-if="loading"></b-spinner>
    <slot></slot>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    variant: {},
    type: {},
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped>
</style>
