import useJwt from "@/auth/jwt/useJwt";
import { login, logout } from "@/auth/utils";
import { encrypt } from "@/libs/crypto-js";
import i18n from "@/libs/i18n";
import Toast from "@/libs/toast";
import router from "@/router";
import axios from "axios";
import { camelizeKeys } from "humps";
import Swal from "sweetalert2";

const baseDomain = "/api";
const baseURL = `${baseDomain}`;
const auth = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

const request = axios.create({
  baseURL,
  headers: {
    Authentication: auth,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  
  }
});

request.interceptors.request.use(
  config => {
    config.headers.Locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "en";

    // Get token from localStorage
    const accessToken = useJwt.getToken();

    config.headers.Authorization = encrypt(useJwt.jwtConfig.authorizationKey)
    // If token is present add it to request"s Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authentication = `${encrypt(accessToken)}`;
    }
    // if (config.data) {
    //   config.data = decamelizeKeys(config.data);
    // }
    return config
  },
  error => Promise.reject(error),
);


request.interceptors.response.use(function (response) {
  if (response.data?.message) {
    Toast.fire({
      icon: "success",
      title: response.data.message,
    });
  }

  if (response.data && (response.headers['content-type'].startsWith('application/json') || response.headers["content-type"] === "application/json;charset=UTF-8, application/json" || response.headers["content-type"] === "application/json")) {
    response.data = camelizeKeys(response.data);
  }

  return Promise.resolve(response);
}, function (error) {
  const { config, response } = error;
  const status = response?.status;
  const originalRequest = config;
  if (status === 401) {
    const code = response?.data?.code;
    if (code === 102) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        useJwt.refreshToken().then((res) => {
          isAlreadyFetchingAccessToken = false;
          const data = res.data?.data;
          login(data);
          onAccessTokenFetched(data.accessToken);
        }).catch(e => {
          const c = e.response?.data?.code;
          if (c === 104) {
            let m = e.response?.data?.message;
            logout();

            // Redirect to login page
            return router.push({ name: "login" }).then(() => {
              Swal.fire({
                title: i18n.t("general.expired"),
                icon: "info",
                text: m,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
          }
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(access_token => {
          originalRequest.headers.Auth = access_token;
          resolve(request(originalRequest))
        })
      });
      return retryOriginalRequest;
    } else if (code === 101 || code === 103 || code === 104) {
      let message = error.response?.data?.message;

      logout();
      // Redirect to login page
      return router.replace({ name: "login" }).then(() => {
        Swal.fire({
          title: i18n.t("general.expired"),
          icon: "info",
          text: message,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    } else {
      logout();
      // Redirect to login page
      return router.replace({ name: "login" }).then(() => {
        Swal.fire({
          title: i18n.t("general.expired"),
          icon: "info",
          text: i18n.t("error.sessionExpired"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    }
  }

  if (status === 404) {
    return router.push("/error-404");
  }

  if (status === 406) {
    return router.push({ name: "login" }).then(() => {
      Swal.fire({
        title: i18n.t("general.warning"),
        icon: "info",
        text: "Please config your device timezone as auto in order to use our platform.",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    });
  }

  if (status === 422) {
    response.data.message = camelizeKeys(response.data?.errors);
  }

  let title = "";
  let message = "";
  if (status !== 404 && status !== 401 && status !== 422) {
    message = response?.data?.message;
  }

  if (response.request.responseType === 'blob'
    && response.data.toString() === '[object Blob]' && status <= 500
  ) {
    /** error response */
    response.data.text().then(res => {
      message = JSON.parse(res)?.message;
      Swal.fire({
        title: status < 500 ? i18n.t("general.attention") : i18n.t("general.warning"),
        icon: status < 500 ? "info" : "error",
        text: message,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    })
  } else {
    if (status === 400) {
      Swal.fire({
        title: i18n.t("general.attention"),
        icon: "info",
        text: message,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else if (message) {
      Swal.fire({
        title: i18n.t("general.warning"),
        icon: "error",
        text: message,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }
  }

  // console.clear();
  return Promise.reject(error);
});

export default request;
