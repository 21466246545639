<template>
  <div>
    <b-row v-for="(data, idx) in value" :key="`row-${idx}`" :class="{
      'saperate-link': idx > 0,
      'pt-1': idx > 0,
    }">
      <b-col md="6">
        <validation-provider #default="{ errors }" :vid="`name-${idx}`" name="field.name" rules="required">
          <b-form-group>
            <label>{{ $t("field.name") }}</label>
            <b-form-input :ref="`name${idx}`" :name="`name-${idx}`" v-model="data.name" :placeholder="$t('field.name')"
              :class="errors.length > 0 ? 'is-invalid' : null" autocomplete="off" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <b-form-group>
          <label class="w-100">
            {{ $t("field.file") }}
            <b-link class="link-underline float-right text-danger" @click="removeRow(idx)">
              {{ $t("button.remove") }}
            </b-link>
          </label>
          <validation-provider #default="{ errors }" :vid="`media-${idx}`" name="field.file" rules="required">
            <n-file-uploader type="file" :ref="`file${idx}`" :name="`file-${idx}`"
              :state="errors.length > 0 ? false : null" :browse-text="$t('button.browse')"
              :placeholder="$t('button.chooseFileOrDropHere')" :drop-placeholder="$t('button.dropHere')" :item="data"
              @change="onFileChange($event, idx)"></n-file-uploader>
            <input :name="`media-${idx}`" type="hidden" v-model="data.mediaId" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button block variant="primary" size="sm" @click="addNewRow">
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import Repository from "@/repositories/RepositoryFactory";
  import NFileUploader from "./NFileUploader.vue";

  const FileRepository = Repository.get("file");

  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BFormFile,
      BButton,
      BLink,
      NFileUploader,
    },
    props: {
      value: {},
      path: {
        type: String,
        default: "image",
      },
    },
    data() {
      return {};
    },
    methods: {
      removeRow(idx) {
        let data = [...this.value];
        data.splice(idx, 1);
        this.$emit("input", data);
      },
      addNewRow() {
        let data = [
          ...this.value,
          {
            name: null,
            mediaId: null,
          },
        ];
        this.$emit("input", data);
      },
      onFileChange(event, idx) {
        let data = [...this.value];

        this.loading = true;
        let formData = new FormData();
        formData.append("file", event);
        this.$refs[`file${idx}`][0].startLoading();
        FileRepository.uploadStream(formData)
          .then((response) => {
            const res = response?.data?.data;
            if (res) {
              data[idx] = {
                ...data[idx],
                ...res,
              };
              this.$emit("input", data);
            }
            this.$refs[`file${idx}`][0].stopLoading();
          })
          .catch()
          .then(() => {
            this.$refs[`file${idx}`][0].stopLoading();
          });
      },
    },
    setup() { },
  };
</script>