import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/vehicle`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "show",
      },
    });
  },
  exportExcel(payload) {
    let params = prepareFilterParams(payload);
    params.fields = payload.fields;
    if (payload.query && payload.query.length) {
      let filters = {
        filters: [...payload.query],
        filtertype: "AND",
      };
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "export-excel-vehicle",
      },
      responseType: "blob",
    });
  },
  exportQrCode(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "export-qr",
      },
      responseType: "blob",
    });
  },
  exportFilterQrCode(payload) {
    const params = prepareFilterParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "export-filter-qr",
      },
      responseType: "blob",
    });
  },
  detail(id) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "detail",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  updateReservePrice(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-reserve-prices",
      },
    });
  },
  destroy(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "delete",
      },
    });
  },
  options(payload) {
    let queries = [];
    if (payload.searchField) {
      queries = [
        {
          searchby: payload.searchField,
          searchoperator: "%",
          search: payload.search
        },
      ];
    } else {
      queries = [
        {
          searchby: "model",
          searchoperator: "%",
          search: payload.search
        },
        {
          searchby: "vin_number",
          searchoperator: "%",
          search: payload.search
        },
      ];
    }
    const params = prepareOptionFilterParams(payload, queries, "OR");
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
  assignUser(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "assign-user",
      },
    });
  },
  requestAmend(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "request-amend",
      },
    });
  },
  confirmInspection(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "confirm-inspection",
      },
    });
  },
  setPrice(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-price",
      },
    });
  },
  updateZone(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-zone",
      },
    });
  },
  getBdPic(userId) {
    return Client.post(resource, {
      userId: userId,
    }, {
      headers: {
        action: "get-bdpic",
      },
    });
  },
  transfer(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "transfer",
      },
    });
  },
};
