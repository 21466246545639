import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareOptionFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/user-mobile`;

export default {
  options(payload) {
    const queries = [
      {
        searchby: "name",
        searchoperator: "%",
        search: payload.search
      }, {
        searchby: "type_id",
        searchoperator: "=",
        search: 2
      },
    ];
    let params = prepareOptionFilterParams(payload, queries);
    if (payload.query) {
      let filters = {
        filters: [],
        filtertype: "AND",
      };
      payload.query.forEach(element => {
        filters.filters.push(element);
      });
      if (payload.sortby) {
        params.sortby = payload.sortby;
      }
      if (payload.sort) {
        params.sort = payload.sort;
      }
      params.filters.push(filters);
    }
    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
};
