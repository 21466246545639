<template>
  <div>
    <div class="form-group">
      <gmap-autocomplete
        class="form-control"
        @place_changed="setPlace"
        :options="autoCompleteOptions"
        :placeholder="$t('field.searchCoordinates')"
      ></gmap-autocomplete>
    </div>

    <GmapMap
      :center="center"
      :zoom="12"
      map-style-id="roadmap"
      :options="mapOptions"
      style="width: 100%; height: 50vmin"
      ref="mapRef"
    >
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @dragend="handleMarkerDrag"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    latitude: {
      type: Number,
      default: 11.573479,
    },
    longitude: {
      type: Number,
      default: 104.919563,
    },
  },
  data() {
    return {
      marker: { position: { lat: 11.573479, lng: 104.919563 } },
      center: { lat: 11.573479, lng: 104.919563 },
      autoCompleteOptions: {
        componentRestrictions: { country: "kh" },
      },
      mapOptions: {
        disableDefaultUI: true,
      },
      initMap: false,
    };
  },
  watch: {
    latitude(value) {
      if (value) {
        this.initLocation();
      }
    },
    longitude(value) {
      if (value) {
        this.initLocation();
      }
    },
  },
  mounted() {
    this.initLocation();
  },
  methods: {
    handleMapClick() {},
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.panToMarker();
      this.$emit("change", this.marker.position);
    },
    setPlace(place) {
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.panToMarker();
      this.$emit("change", this.marker.position);
    },
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
    },
    initLocation() {
      if (!this.initMap && this.latitude && this.longitude) {
        this.marker.position.lat = this.latitude;
        this.marker.position.lng = this.longitude;
        this.center.lat = this.latitude;
        this.center.lng = this.longitude;
        this.initMap = true;
      }
    },
  },
};
</script>

<style scoped>
</style>
