import i18n from '@/libs/i18n'
import { $themeConfig } from '@themeConfig'
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import auctionSchedule from './routes/auctionSchedule'
import auth from './routes/auth'
import biddingReport from './routes/biddingReport'
import dealer from './routes/dealer'
import errors from './routes/errors'
import guideline from './routes/guideline'
import location from './routes/location'
import logisticInspector from './routes/logisticInspector'
import payment from './routes/payment'
import profile from './routes/profile'
import role from './routes/role'
import setting from './routes/setting'
import user from './routes/user'
import vehicle from './routes/vehicle'
import vehicleBrand from './routes/vehicleBrand'
import vehicleColor from './routes/vehicleColor'
import vehicleFuelType from './routes/vehicleFuelType'
import vehicleModel from './routes/vehicleModel'
import zone from './routes/zone'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line no-restricted-syntax
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'breadcrumb.dashboard',
        resource: 'dashboard',
        breadcrumb: [
          {
            text: 'breadcrumb.dashboard',
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...vehicleBrand,
    ...vehicleColor,
    ...vehicleFuelType,
    ...vehicle,
    ...zone,
    // ...lot,
    ...logisticInspector,
    ...dealer,
    ...auctionSchedule,
    ...biddingReport,
    ...payment,
    ...setting,
    ...guideline,
    ...vehicleModel,
    ...location,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  Vue.nextTick(() => {
    const { appName } = $themeConfig.app
    document.title = `${to.meta.title ? i18n.tc(to.meta.title) : i18n.tc(to.meta.pageTitle)} | ${appName}`
  })

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      })
    }

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  return next()
})

export default router
